import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hello from "../components/home/hello"
import BlogRoll from "../components/home/blogroll"

import JSONData from "../data/texts.json"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hello texts={JSONData.pages.home.hello} />
    <BlogRoll/>
  </Layout>
)

export default IndexPage
