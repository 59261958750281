import React from 'react';
import { StaticImage } from "gatsby-plugin-image"


const Hello = ({ texts }) => {
  console.log(texts)
  return (
    <div className="pt-8 md:pt-24">
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        {/*<!--Left Col-->*/}
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <h1 className="my-4 text-5xl font-bold leading-tight w-full">
            {texts.greeting} <br/>
            {texts.name}
          </h1>
          <p className="leading-normal text-2xl mb-8">
            {texts.description}
          </p>
        </div>
        {/*<!--Right Col-->*/}
        <div className="w-full md:w-3/5 py-6 text-center">
          <StaticImage
            src="../../images/indrek.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            className="w-full md:w-4/5 z-50 rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}

export default Hello
