import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { parseBlogNode } from '../../helpers/blogparser'

export const BlogRoll = () => {
  return (
    <StaticQuery
      query={graphql`
        query Medium {
          allFeedBlog(limit: 3, sort: {order: DESC, fields: isoDate}) {
            edges {
              node {
                link
                isoDate(formatString: "YYYY-MM-DD")
                title
                categories
                content {
                  encoded
                }
              }
            }
          }
        }
      `}
      render={({allFeedBlog}) => {
        const edges = allFeedBlog.edges

        return (
          <div className="pt-24">
              <h3 className="text-3xl font-semibold my-8 text-center w-full">My writings</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-8 md:p-0">
              {edges.map((edge, i) => {
                const post = parseBlogNode(edge.node);

                return (
                  <figure className="bg-slate-100 rounded-xl p-8 no-underline" key={`blog-${i}`}>
                    <a href={post.link} className="features-small-item p-0 pointer no-underline">
                      <div className="space-y-4">
                        <blockquote>
                          <p className="text-lg font-medium">
                            {post.title}
                          </p>
                        </blockquote>
                        <figcaption className="font-medium">
                          <div className="text-slate-500">
                            {post.date}
                          </div>
                        </figcaption>
                      </div>
                    </a>
                  </figure>
                )}
              )}
            </div>
          </div>
        )
      }}
    />
  )
}

export default BlogRoll